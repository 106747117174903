export default store => [{
	name: "home",
	path: "/",
	component: () => import("../pages/Home.vue")
}, {
	name: "login",
	path: "/login",
	component: () => import("../pages/Login.vue")
}, {
	name: "register",
	path: "/register",
	component: () => import("../pages/Register.vue")
}, {
	name: "logout",
	path: "/logout",
	beforeEnter(to, from, next) {
		store.dispatch("account/logout");

		if(from.name === null) {
			return next({
				replace: true,
				name: "home"
			});
		} else {
			return next({
				replace: true,
				path: from.path
			});
		}
	}
}, {
	name: "profile",
	path: "/profiles/:id",
	component: () => import("../pages/Profile.vue")
}, {
	name: "404",
	path: "/:catchAll(.*)",
	component: () => import("../pages/404.vue")
}];

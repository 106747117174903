import account from "./account";
import { createStore } from "vuex";
import feed from "./feed";
import users from "./users";

export default async () => {
	const store = createStore({
		modules: {
			account,
			feed,
			users
		}
	});

	await store.dispatch("account/sync");
	await store.dispatch("feed/update");

	return store;
};

import "./main.scss";

import { createApp } from "vue";
import createRouter from "./router/index";
import createStore from "./store/index";
import registerComponents from "./components/index";

async function init() {
	const store = await createStore();
	const router = createRouter(store);
	const app = createApp({});

	registerComponents(app);
	app.use(router);
	app.use(store);
	app.mount("#app");
}

init();

import apiRequest from "../apiRequest";

export default {
	namespaced: true,
	state: {
		feed: []
	},
	getters: {
		getPost(state) {
			return id => state.feed.find(post => post.id === id);
		},
		getComment(state) {
			return (postId, commentId) => state.feed.find(post => post.id === postId)
				.comments.find(comment => comment.id === commentId);
		}
	},
	mutations: {
		setFeed(state, feed) {
			state.feed = feed;
		},
		pushFeed(state, newPost) {
			state.feed.unshift(newPost);
		},
		pushComment(state, { postId, comment }) {
			state.feed.find(post => post.id === postId).comments.push(comment);
		},
		updateUpvotes(state, { id, upvotes }) {
			state.feed.find(post => post.id === id).upvotes = upvotes;
		},
		updateCommentUpvotes(state, { postId, commentId, upvotes }) {
			state.feed.find(post => post.id === postId)
				.comments.find(comment => comment.id === commentId).upvotes = upvotes;
		}
	},
	actions: {
		async update({ commit }, id) {
			const { body: feed } = await apiRequest({
				path: "posts/"
			});

			commit("setFeed", feed);
		},
		async createPost({ commit, rootState }, { title, content }) {
			try {
				const { body: post } = await apiRequest({
					path: "posts/",
					method: "POST",
					body: { title, content },
					headers: {
						Authorization: rootState.account.token
					}
				});

				commit("pushFeed", post);
				commit("account/addUpvote", post.id, { root: true });
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			return { error: null };
		},
		async createComment({ commit, rootState }, { postId, content }) {
			try {
				const { body: comment } = await apiRequest({
					path: `posts/${postId}/comments/`,
					method: "POST",
					body: { content },
					headers: {
						Authorization: rootState.account.token
					}
				});

				commit("pushComment", { postId, comment });
				commit("account/addUpvote", comment.id, { root: true });
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			return { error: null };
		},
		async upvotePost({ getters, commit, rootState }, id) {
			try {
				await apiRequest({
					path: `posts/${id}/upvotes/`,
					method: "PUT",
					headers: {
						Authorization: rootState.account.token
					}
				});

				const post = getters.getPost(id);
				commit("updateUpvotes", { id, upvotes: post.upvotes + 1 });
				commit("account/addUpvote", id, { root: true });
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			return { error: null };
		},
		async removePostUpvote({ getters, commit, rootState }, id) {
			try {
				await apiRequest({
					path: `posts/${id}/upvotes/`,
					method: "DELETE",
					headers: {
						Authorization: rootState.account.token
					}
				});

				const post = getters.getPost(id);
				commit("updateUpvotes", { id, upvotes: post.upvotes - 1 });
				commit("account/removeUpvote", id, { root: true });
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			return { error: null };
		},
		async upvoteComment({ getters, commit, rootState }, { commentId, postId }) {
			try {
				await apiRequest({
					path: `posts/${postId}/comments/${commentId}/upvotes/`,
					method: "PUT",
					headers: {
						Authorization: rootState.account.token
					}
				});

				const comment = getters.getComment(postId, commentId);
				commit("updateCommentUpvotes", { postId, commentId, upvotes: comment.upvotes + 1 });
				commit("account/addUpvote", commentId, { root: true });
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			return { error: null };
		},
		async removeCommentUpvote({ getters, commit, rootState }, { commentId, postId }) {
			try {
				await apiRequest({
					path: `posts/${postId}/comments/${commentId}/upvotes/`,
					method: "DELETE",
					headers: {
						Authorization: rootState.account.token
					}
				});

				const comment = getters.getComment(postId, commentId);
				commit("updateCommentUpvotes", { postId, commentId, upvotes: comment.upvotes - 1 });
				commit("account/removeUpvote", commentId, { root: true });
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			return { error: null };
		}
	}
};

import apiRequest from "../apiRequest";

export default {
	namespaced: true,
	state: {
		id: null,
		upvotes: new Set(),
		token: localStorage.getItem("token"),
		synced: false,
		username: null,
		createdAt: null
	},
	mutations: {
		setSynced(state, value) {
			state.synced = value;
		},
		logout(state) {
			localStorage.removeItem("token");

			state.token = null;
			state.id = null;
			state.upvotes.clear();
		},
		setToken(state, token) {
			localStorage.token = token;
			state.token = token;
		},
		updateData(state, payload) {
			state.id = payload.id;
			state.upvotes = new Set(payload.upvotes);
			state.username = payload.username;
			state.createdAt = payload.createdAt;
		},
		addUpvote(state, id) {
			state.upvotes.add(id);
		},
		removeUpvote(state, id) {
			state.upvotes.delete(id);
		}
	},
	actions: {
		async sync({ commit, state }) {
			if(!state.synced) {
				if(state.token) {
					try {
						const { body } = await apiRequest({
							path: "users/@me/",
							headers: {
								Authorization: state.token
							}
						});

						commit("updateData", body);
					} catch(err) {
						if(err.resp && [400, 401, 500].includes(err.resp.status)) commit("logout");
					}
				}

				commit("setSynced", true);
			}

			return true;
		},
		async login({ commit, dispatch }, { username, password }) {
			try {
				const { body } = await apiRequest({
					path: "users/login/",
					method: "POST",
					body: { username, password }
				});

				commit("setToken", body.token);
				commit("updateData", body.user);
				commit("setSynced", true);
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}


			dispatch("sync");
			return { error: null };
		},
		async register({ commit, dispatch }, { username, password }) {
			try {
				const { body } = await apiRequest({
					path: "users/",
					method: "POST",
					body: { username, password }
				});

				commit("setToken", body.token);
				commit("updateData", body.user);
				commit("setSynced", true);
			} catch(err) {
				if(err.resp) {
					return { error: err.resp.body.message };
				}
			}

			dispatch("sync");
			return { error: null };
		},
		logout({ commit }) {
			commit("logout");
		}
	}
};

import apiRequest from "../apiRequest";

export default {
	namespaced: true,
	state: {
		users: JSON.parse(sessionStorage.getItem("userCache") ?? "{}")
	},
	getters: {
		hasUser(state) {
			return id => id in state.users;
		}
	},
	mutations: {
		addUser(state, user) {
			state.users[user.id] = user;
		}
	},
	actions: {
		async fetchUser({ commit, rootState, dispatch }, id) {
			let user;
			if(rootState.account.id === id) {
				user = {
					id,
					username: rootState.account.username,
					createdAt: rootState.account.createdAt
				};
			} else {
				({ body: user } = await apiRequest({
					path: `/users/${id}/`,
					headers: {
						Authorization: rootState.account.token
					}
				}));
			}

			commit("addUser", user);
			dispatch("save");

			return user;
		},
		save({ state }) {
			sessionStorage.setItem("userCache", JSON.stringify(state.users));
		},
		async getUserById({ getters, dispatch, state }, id) {
			if(getters.hasUser(id)) return state.users[id];
			else return await dispatch("fetchUser", id);
		}
	}
};
